.NavBar {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7px 20px;
  position: fixed;
  top: 0;
  z-index: 6;
  transition: all 0s;
}

.navInnerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  z-index: 5;
}

.navInnerBox svg {
  width: 166px;
  min-width: 166px;
}

.navLineMenuBox {
  width: 45px;
  min-width: 45px;
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  padding: 1px 0;
  position: relative;
}

.NavSLine {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 87%;
  translate: -120%;
  transition: all 0.5s cubic-bezier(0, 0.69, 0.75, 0.9);
}

.NavSLineActive {
  translate: 0%;
}

.l3 {
  border-top: 1px solid #fff;
  width: 100%;
}

.navInnerBox1 {
  justify-content: start;
  gap: 11px;
}

.lineMenuBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
  translate: 0%;
  transition: all 0.5s cubic-bezier(0, 0.69, 0.75, 0.9);
}

.lineMenuBoxMActive {
  translate: 220%;
}

.nml {
  width: 100%;
  position: absolute;
  transition: all 0.5s cubic-bezier(0, 0.69, 0.75, 0.9);
}

.l1 {
  border-top: 1px solid #fff;
  translate: -120%;
}

.l2 {
  border-top: 1px solid #ffffff;
}

.navLineMenuBox:hover .l2 {
  translate: 120%;
}

.navLineMenuBox:hover .l1 {
  translate: 0%;
}

.l1v2 {
  transition: all 1s cubic-bezier(0.05, 0.82, 0.66, 0.76);
}

.navItemTExt {
  font-weight: 600 !important;
  color: #fff;
  cursor: pointer;
}


.cartWUBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.navCartText {
  font-size: 14px !important;
  color: #ffff;
  cursor: pointer;
}

.cartWUBox svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  transition: all 0.5s;
}
.cartWUBox svg g path {
  stroke: #ffffffbe;
  transition: all 0.5s;
}

.cartWUBox svg:hover g path {
  stroke: #ffffff;
}

.TextFlipBox {
  width: 92px;
  height: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.TextFlipBox p {
  position: absolute;
  transition: all 0.8s;
  color: #fff;
}

.TextFlipBox .text2 {
  transform: translateY(20px);
}

.TextFlipBox:hover .text1 {
  transform: translateY(-20px);
}

.TextFlipBox:hover .text2 {
  transform: translateY(0px);
}

.grayBox {
  width: 100%;
  height: 100vh;
  background: #2b2b2b6b;
  position: absolute;
  top: 0px;
  left: 0px;
}

.navMenu {
  width: 100%;
  height: 0px;
  background-color: #0e0e0e;
  z-index: 4;
  pointer-events: all;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.5s;
  padding: 0 2%;
  overflow: hidden;
  transition: all 0.8s;
}
.navMenuActive {
  height: 110vh;
}

.navLastBox {
  width: 100%;
  border: 1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 2%;
}

.navMenuMainSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 137px;
  gap: 20px;
}

.navMenuMainSection .TextFlipBox {
  width: 100%;
  height: 36px;
  align-items: start;
}

.nf2t .TextFlipBox p {
  font-size: 28px !important;
}

.TextFlipBox:hover .text1 {
  transform: translateY(-40px);
}

.navMenuMainSection .TextFlipBox:hover .text2 {
  transform: translateY(0px);
}

.navMenuMainSection .TextFlipBox .text2 {
  transform: translateY(40px);
}

.navMenuMainSection .fMidInBox {
  width: auto;
  gap: 25px;
}

.navMenuMainSection .navItemTExt {
  font-weight: 400 !important;
}

.NavBar .fLastBox {
  margin-top: 60px;
  justify-content: start;
  gap: 10px;
}

.NavBar .fLastBox .fLastInBox {
  width: 34%;
}

.NavLastMS .TextFlipBox p {
  font-size: 14px !important;
  color: #ccc;
}

.NavLastMS {
  gap: 14px !important;
  margin-top: 54px;
}

.NavLastMS .TextFlipBox {
  height: 18px;
}

.NavLastMS .TextFlipBox:hover .text1 {
  transform: translateY(-20px);
}

.NavLastMS .TextFlipBox .text2 {
  transform: translateY(40px);
}

.nvMTextB .TextFlipBox p {
  font-family: "TimesNow" !important;
}

.shop-now {
  font-size: 14px !important;
}
.DshopText {
  display: none !important;
}
.cartWUBox svg {
  min-width: unset;
}
.navMenu .fLastBox {
  display: flex !important;
  flex-direction: column;
  align-items: start;
}
.navMenu .fLastInBox .TextFlipBox {
  align-items: start;
}



.itText p {
  font-family: __timesNow_ce0a62 !important;
  font-style: italic;
}

.cartBox {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0%;
  height: 100vh;
  background: #fff;
  z-index: 9999;
  transition: all 0.7s;
  overflow: hidden;
}
.cartBoxActive {
  width: 33%;
}
.cartInnerBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.cartNav {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cartCrossBtn {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0000004f;
  cursor: pointer;
}
.cartCrossBtn img {
  width: 16px;
  height: 16px;
}
.cartNav p {
  font-size: 14px !important;
  color: #000;
}
.cartListBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 62%;
  margin: 20px 0;
  overflow-y: auto;
}
.cartTotalPBox {
  width: 100%;
  height: 86px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.totalLavel {
  font-size: 21px !important;
  color: #262626;
}
.totalValue {
  font-size: 14px !important;
  color: #262626;
}
.anountText {
  font-size: 14px !important;
  color: #0e0e0e;
}
.ntb {
  border-top: none;
}
.cardFBtnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.cardFBtnBox .orText {
  font-size: 12px;
  color: #8c8c8c;
  position: relative;
  bottom: -13px;
  margin: 0 3% !important;
}
.cfBlackBtn {
  background: #000;
  transition: all 0.6s;
}
.cfBlackBtn:hover {
  background: #fff;
}
.cfBlackBtn:hover span {
  transform: none !important;
}
.cfBlackBtn:hover p {
  color: #0e0e0e;
}
.cardFBtnBox .discoverBtn {
  width: 45%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 26px;
}
.cfBlackBtn p {
  color: #fff;
}
.productItem {
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  margin-top: 10px;
}
.cProductImg {
  min-width: 90px;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
}
.cProductImg img {
  width: 100%;
  height: 100%;
}
.productItemTextBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 18px;
}
.productBtnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cartProductTitle {
  font-size: 14px !important;
  color: #8c8c8c;
}
.productBtnBox .cartCrossBtn {
  width: 38px;
  height: 38px;
}
.cartBoxActive {
  width: 100%;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .navLineMenuBox {
    width: 50px;
  }
  .NavBar {
    height: 50px;
    padding: 7px 30px;
  }
  .navInnerBox {
    width: 32.5%;
  }
  .navInnerBox1 {
    gap: 30px;
  }
  .cartWUBox {
    gap: 30px;
  }
  .navInnerBox1 {
    width: 32.5%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .NavBar {
    padding: 26px 30px;
    height: 77px;
  }
  .navLineMenuBox {
    width: 95px;
  }
  .DshopText {
    display: flex !important;
  }
  .navMenuActive {
    height: 423px;
  }
  .navMenuMainSection {
    flex-direction: row;
    gap: 0;
  }
  .navMenuMainSection .fMidInBox {
    width: 34%;
    gap: 25px;
  }
  .nf2t .TextFlipBox p {
    font-size: 42px !important;
    line-height: 46px !important;
    font-weight: 400 !important;
    /* font-style: italic; */
  }
  .navInnerBox {
    justify-content: space-between;
  }
  .NavLastMS {
    padding-left: 18%;
    margin-top: 0px;
  }
  .paddingNL {
    padding-left: 18%;
  }
  .NavBar .fLastBox {
    gap: 0;
  }
  .NavBar .fLastBox {
    margin-top: 83px;
  }
  .navMenu .fLastBox {
    flex-direction: row;
  }
  .navInnerBox1 {
    width: 21.5%;
  }
  .cartBoxActive {
    width: 33%;
  }
}
