@font-face {
  font-family: __galano_7b399e;
  src: url(./Assets/Fonts/447b9ab2bd950bb8-s.p.woff2) format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: __galano_7b399e;
  src: url(./Assets/Fonts/46bb91aefc54141b-s.p.woff2) format("woff2");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: __timesNow_ce0a62;
  src: url(./Assets/Fonts/814ab920ffa37260-s.p.woff2) format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: __timesNow_ce0a62;
  src: url(./Assets/Fonts/da685b01c598cea8-s.p.woff2) format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

html:not(.dev) {
  /* scrollbar-width: none !important; */
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  user-select: none;
  font-family: __galano_7b399e !important;
  font-weight: 400 !important;
  color: #ffffff;
}

body {
  background-color: unset;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  background: #0e0e0e;
}

.pointer {
  cursor: pointer;
}

/* Target the scrollbar thumb and track in WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

/* Set the color of the scrollbar thumb in WebKit-based browsers */
::-webkit-scrollbar-thumb {
  background-color: #cbb18e;
  /* Color of the scrollbar thumb */
}

/* Set the color of the scrollbar track in WebKit-based browsers */
::-webkit-scrollbar-track {
  background-color: #4d4d4d6b;
  /* Color of the scrollbar track */
}

/* Target the scrollbar thumb and track in Mozilla-based browsers (Firefox) */
::-moz-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

/* Set the color of the scrollbar thumb in Mozilla-based browsers */
::-moz-scrollbar-thumb {
  background-color: #cbb18e;
  /* Color of the scrollbar thumb */
}

/* Set the color of the scrollbar track in Mozilla-based browsers */
::-moz-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the scrollbar track */
}
