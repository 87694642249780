.RoundlogoBox {
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 1;
  transition: all 0.5s linear;
  /* border: 1px solid red; */
}
.RoundlogoBox img {
  width: 100%;
  height: 100%;
}
