.N25GlobalPage {
  width: 100%;
  padding: 0 20px;
  padding-top: 100px;
}
.ngHeader {
  font-size: 33px !important;
  color: #fff;
}
.ngNText {
  color: #fffc;
}
.N25GlobalPage .mapBox {
  width: 100%;
  height: 82vh;
  padding-top: 50px;
  border-top: 1px solid #fff;
  margin-top: 20px;
  position: relative;
  margin-bottom: 100px;
}
.mapBox .maplibregl-map {
  width: 100% !important;
  height: 100% !important;
  cursor: grab;
}
.nGCardsHeader {
  width: 100%;
  font-size: 21px !important;
  margin-bottom: 30px !important;
}
.ngCardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;
}
.ngCard {
  width: 100%;
  height: 155px;
  display: flex;
  flex-direction: column;
  border: 1px solid #fff;
  padding: 20px;
}
.ngCardHeader {
  font-family: __timesNow_ce0a62 !important;
  font-size: 31px !important;
  color: #fff;
  margin-bottom: 6px !important;
}
.ngCardNText {
  font-size: 14px !important;
  color: #fffc;
}
.N25GlobalPage .TextFlipBox {
  width: 100%;
  align-items: start;
  margin-bottom: 5px;
}

.N25GlobalPage .TextFlipBox .navItemTExt {
  font-size: 14px !important;
  color: #fffc;
  font-weight: 400 !important;
}
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: grab; /* Ensures the cursor indicates that the map can be dragged */
}
.mapBox .maplibregl-popup-content {
  background: #a1a1a1;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .N25GlobalPage {
    padding: 0 25px;
  }
  .ngHeader {
    font-size: 38px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .N25GlobalPage {
    padding-top: 200px;
  }
  .ngHeader {
    font-size: 51px !important;
    line-height: 69px !important;
  }
  .nGCardsHeader {
    width: 32%;
    margin-bottom: 30px !important;
  }
  .ngCardBox {
    flex-direction: row;
  }
  .ngCard {
    width: 32%;
    height: 186px;
  }
  .ngCardHeader {
    margin-bottom: 8%;
  }
  .N25GlobalPage .TextFlipBox {
    margin-bottom: 3px;
  }
}
