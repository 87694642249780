.homeCotainer {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  /* Full viewport height */
  overflow: hidden;
  /* Hide any overflow */
  display: flex;
  justify-content: center;
  /* Center horizontally */
  flex-direction: column;
}

.imageFBox {
  min-width: 350px;
  min-height: 412px;
  position: relative;
  cursor: pointer;
  max-width: 100%;
}

/* Medium screens (e.g., tablets) */
@media (max-width: 1024px) {
  .imageFBox{
    min-width: 250px;
  }
}

/* Small screens (e.g., mobile phones) */
@media (max-width: 768px) {
  .imageFBox {
      min-width: 200px; /* Further reduce the minimum width */
  }
}

/* Extra small screens (e.g., very small devices) */
@media (max-width: 480px) {
  .imageFBox {
      min-width: 150px; /* Smallest minimum width */
  }
}





.imageFBox p {
  font-size: 12px !important;
  line-height: 1.2;
  color: #cccccc;
  text-align: center;
  position: absolute;
  bottom: -10%;
  left: 12%;
  line-height: 27px;
}

.imageFBox .image1 {
  position: absolute;
  width: 100%;
  height: 100%;
  webkit-transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.imageFBox .image2 {
  position: absolute;
  width: 100%;
  height: 100%;
  webkit-transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.imageFBox .image2 {
  -webkit-transform: translate(10%, -10%);
  -moz-transform: translate(10%, -10%);
  -ms-transform: translate(10%, -10%);
  transform: translate(10%, -10%);
}

.imageFBox .image1 {
  webkit-transform: translate(-5%, 5%);
  -moz-transform: translate(-5%, 5%);
  -ms-transform: translate(-5%, 5%);
  transform: translate(-5%, 5%);
}

.homeBg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeMheader {
  font-size: 38px !important;
  color: #fff;
  z-index: 1;
  padding-left: 25px;
}

.TimesNowText {
  font-family: __timesNow_ce0a62 !important;
  font-weight: 400;
  font-style: italic;
}

.home2ndSection {
  width: 100%;
  /* padding: 0px 27px 5px 33px; */
  padding: 0 15px;
  background: #0e0e0e;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home2ndFBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.home2ndFBox .h2nImg1 {
  width: 100%;
}
.home2ndFBox .h2nTextBox {
  width: 100%;
}
.home2ndFBox .h2nImg2 {
  width: 100%;
}

.home2ndFBox .h2nImg2 {
  display: flex;
  align-items: end;
  padding-bottom: 20px;
}

.home2ndFBox .h2nImg1 img {
  width: 100%;
  opacity: 0.7;
}

.home2ndFBox .h2nImg2 img {
  width: 100%;
  opacity: 0.7;
}

.h2nTextBox {
  /* position: relative; */
}

.h2nTextBox p {
  font-size: 28px !important;
  line-height: 35px;
  color: #fff;
  margin-bottom: 20px !important;
}

.h2nTextBox span {
  width: 78%;
  display: block;
  font-size: 14px !important;
  color: #ffffffd2;
  margin-top: 170px;
}

.discoverySection {
  width: 100%;
  /* height: 756px; */
  /* border: 1px solid red; */
  position: relative;
  /* padding-left: 147px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 0 15px;
  margin-top: 13px;
}

.disBg {
  width: 100%;
  margin-bottom: 20px;
}

.discoverySection .dicText1 {
  font-size: 14px !important;
  color: #fff;
  z-index: 1;
}

.discoverySection .dicText2 {
  font-size: 28px !important;
  color: #fff;
  z-index: 1;
  line-height: 1.2 !important;
}

.discoverySection .dicText2 span {
  font-size: 42px !important;
}

.discoverySection .dicText3 {
  font-size: 14px !important;
  color: #cccccc;
  z-index: 1;
  max-width: 350px;
}

.shopSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 60px 0;
}

.shopSection .b1 {
  content: "";
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #262626;
  -webkit-clip-path: polygon(0% 0%, 0% 102%, 102% 102%);
  clip-path: polygon(0% 0%, 0% 102%, 102% 102%);
  box-shadow: 0 1px 0 #262626;
}

.shopSection .b3 {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #262626;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  box-shadow: 0 -1px 0 var(--color-dark);
  -webkit-clip-path: polygon(-2% -2%, 100% -2%, 100% 100%);
  clip-path: polygon(-2% -2%, 100% -2%, 100% 100%);
  z-index: 0;
}

.shopSection .b2 {
  width: 100%;
  height: 100%;
  background: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.threeTBox {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  padding-bottom: 150px;
}
.TTItem {
  position: relative;
}
.threeTBox div:nth-child(2) {
  top: 40px;
}
.threeTBox div:nth-child(3) {
  top: 80px;
}
.discoverBtn {
  width: 100px;
  height: 44px;
  position: relative;
  z-index: 1;
  padding: 15px 60px 15px 15px;
  color: #ffffff;
  display: block;
  z-index: 1;
  -webkit-transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  cursor: pointer;
  margin-top: 50px;
}

.discoverBtn p {
  font-size: 12px;
  color: #cccccc;
}

.discoverBtn .DBLine {
  display: block;
  content: "";
  height: 1px;
  width: 30px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform-origin: left;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.discoverBtn .dbsLine {
  background-color: #8c8c8c;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
}

.dbTops {
  height: 1px;
  width: 100%;
  top: 0;
  left: 0;
  transform-origin: left;
}

.dbRighs {
  height: 100%;
  width: 1px;
  top: 0;
  right: 0;
  transform-origin: top;
}

.dbBottoms {
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform-origin: right;
}

.dbLefts {
  height: 100%;
  width: 1px;
  top: 0;
  left: 0;
  transform-origin: bottom;
}

.discoverBtn:hover .dbTops,
.discoverBtn:hover .dbBottoms {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.discoverBtn:hover .dbRighs,
.discoverBtn:hover .dbLefts {
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}

.discoverBtn:hover .DBLine {
  -webkit-transform: scaleX(0.6);
  -moz-transform: scaleX(0.6);
  -ms-transform: scaleX(0.6);
  transform: scaleX(0.6);
}

.shopTextBox {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  gap: 32%;
  padding: 0 30px;
  padding-top: 150px;
}

.shopTBHeader {
  width: 42%;
  font-size: 38px !important;
  color: #fff;
}

.shopSecondTextBox {
  width: 250px;
}

.shopSecondTextBox p {
  font-size: 14px !important;
  color: #cccccc;
}

.shopSLabelTextBox {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-top: 30px;
}

.shopSLabelTextBox span {
  font-size: 12px !important;
  color: #fff;
}

.shopSLabelTextBox strong {
  font-size: 12px !important;
  color: #fff;
  font-weight: 600 !important;
}

.shopSLabelTextBox .ssltbLine {
  height: 1px;
  width: 34px;
  background-color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  top: 2px;
  left: 18px;
  transform-origin: left;
}

.shopSLabelTextBox:hover .ssltbLine {
  -webkit-transform: scaleX(0.6);
  -moz-transform: scaleX(0.6);
  -ms-transform: scaleX(0.6);
  transform: scaleX(0.6);
}

.meticulousSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  padding: 0 10%;
  gap: 150px;
  margin-bottom: 100px;
  margin-top: 200px;
}

.metTextBox {
  width: 45%;
}

.metImgBox {
  width: 55%;
}

.metImgBox img {
  width: 100%;
  height: 100%;
}

.metTextBox .metTextSubText {
  font-size: 14px !important;
  color: #cccccc;
}

.meticulousSection .shopTBHeader {
  width: unset;
}

.meticulousSection .discoverBtn {
  width: 85px;
  padding-left: 0px;
}

.AmbassadorsSection {
  width: 100%;
  padding: 0 30px;
  margin-bottom: 100px;
}

.ambHeader {
  font-size: 33px !important;
  color: #fff;
  line-height: 58px !important;
}

.ambCardBox {
  margin-bottom: 80px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.ambCard {
  width: 23%;
  /* border: 1px solid red; */
}

.ambCard img {
  max-width: 100%;
  height: auto;
}

.ambCard p {
  font-size: 14px !important;
  color: #cccccc;
  white-space: nowrap;
}

.ambCard p:first-child {
  font-size: 12px !important;
  color: #ffffff !important;
}

.ambCard .discoverBtn {
  width: 172px;
  padding-left: 0px;
  margin-top: 0px;
}

.foodSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.foodSection p {
  color: #fff;
  font-size: 11px;
}

.footTitle1 {
  font-size: 11px !important;
}

.footTitle2 {
  max-width: 454px;
  font-size: 51px !important;
  text-align: center;
  line-height: 60px !important;
  margin: 20px auto !important;
}

.footTitle2 span {
  font-size: 58px !important;
}

.footTitle3 {
  margin: 0 auto;
  font-size: 14px !important;
  max-width: 500px;
  text-align: center;
  color: #cccccc !important;
}

.foodBtnBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.foodBtnBox .discoverBtn {
  width: 160px;
  height: 50px;
  padding: 18px 15px 15px 15px;
  margin: 0px;
}

.foodVideoSection {
  width: 70%;
  margin: 100px 0;
}

.chefSection {
  width: 100%;
  /* height: 670px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 5%;
  margin-bottom: 200px;
  margin-top: 170px;
}

.chefTextBox {
  width: 45%;
}

.chefImgBox {
  width: 45%;
}

.chefSection .summery14 {
  width: 58%;
  margin: 20px 0;
}

.summery14 {
  font-size: 14px !important;
  color: #cccccc;
}

.chefImgBox img {
  width: 100%;
  /* height: 100%; */
}

.chefSection .discoverBtn {
  width: 200px;
  padding-left: 0px;
}

.ourValueSection {
  width: 100%;
  margin-bottom: 100px;
  overflow: hidden;
  z-index: 2;
  position: relative;
  background: #0e0e0e;
}

.ourValueSection .ambHeader {
  padding-left: 2%;
}

.ourValueSection .summery14 {
  width: 25%;
  margin-top: 20px;
  padding-left: 2%;
}

.ourValueSection .ambHeader {
  font-size: 38px !important;
}

.ourValueSection .ambHeader span {
  font-family: __timesNow_ce0a62 !important;
  font-size: 42px !important;
  font-style: normal;
}

/* .ovCard {
  width: 29%;
  min-width: 400px;
  min-height: 507px;
  height: 100%;
  padding-right: var(--spacing-xxs);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
} */
/* 
.ovCard .ovCInneerBox {
  height: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  padding: 30px;
}

.ovCard img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  vertical-align: top;
  -webkit-user-drag: none;
  -webkit-user-select: none;
} */

.ovTextBox {
  width: 230px;
  padding: 20px;
  background: #0e0e0e;
  border-radius: 2px;
  position: absolute;
  left: 25px;
  bottom: 25px;
}

.ovTbHeader {
  font-size: 30px !important;
  color: #fff;
  font-family: __timesNow_ce0a62 !important;
  line-height: 1.2 !important;
  font-weight: 400;
  font-style: normal;
}

.ovTextBox .summery14 {
  width: 100%;
  line-height: 1.2;
  margin-top: 5px;
}

.mailSection {
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 11% 0;
}

.mailSection .ambHeader {
  font-size: 38px !important;
}

.gsap-marker-end {
  display: none !important;
}

.gsap-marker-start {
  display: none !important;
}

.gsap-marker-scroller-end {
  display: none !important;
}

.gsap-marker-scroller-start {
  display: none !important;
}

.mailSection .summery14 {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 3px;
}

.mailInputBox {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  border: 1px solid #ffffff80;
}

.submitBtn {
  min-width: 60px;
  height: 100%;
  border-left: 1px solid #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e0e0e;
  transition: all 0.8s;
  cursor: pointer;
}

.submitBtn svg {
  transform: rotate(270deg);
  width: 40px;
  height: 35px;
}

.submitBtn svg path {
  fill: #fff;
  transition: all 0.8s;
}

.submitBtn:hover {
  background: #fff;
}

.submitBtn:hover svg path {
  fill: #000;
}

.mailInputBox input {
  width: 100%;
  height: 100%;
  border: none;
  background: #0000;
  color: #fff;
  padding-left: 20px;
  margin: 0px 0 !important;
}

.mailInputBox input:focus {
  outline: none;
}

.MailCheckBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-top: 25px;
}

.MailCheckBox .check {
  min-width: 18px;
  min-height: 18px;
  border: 1px solid #fff;
  cursor: pointer;
  margin-right: 10px;
}

.checkActive {
  background: #fff;
}

.MailCheckBox p {
  color: #fff !important;
  font-size: 12px;
  cursor: pointer;
}

.termsbox {
  margin-left: 9px;
  display: flex;
  flex-direction: column;
}

.termsbox > div {
  width: 100%;
  border-bottom: 1px solid #cccccc;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: left;
}

.termsbox:hover > div {
  transform: scaleX(0);
}

.chefSection .ambHeader span {
  font-size: 58px !important;
}

#video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 37.5vw;
  width: 80%;
  position: relative;
  cursor: none;
  max-width: 100%;
}

#play-button {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.3);
  border: none;
  opacity: 1;
  cursor: none;
}

#video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.play-icon {
  font-size: 16px;
  color: #ffffff;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .ambHeader {
    font-size: 33px !important;
  }

  .mailSection .ambHeader {
    font-size: 28px !important;
  }

  .imageFBox p {
    font-size: 14px !important;
    bottom: 0%;
    left: 35%;
  }
  .h2nTextBox p {
    width: 40%;
    font-size: 25px !important;
    line-height: 30px;
    position: absolute;
  }
  .home2ndFBox {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
  }
  .home2ndFBox .h2nImg1 {
    width: 30%;
  }
  .home2ndFBox .h2nTextBox {
    width: 40%;
  }
  .home2ndFBox .h2nImg2 {
    width: 30%;
  }
  .discoverySection {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 760px) {
  .homeMheader {
    font-size: 28px !important;
    line-height: 1 !important;
    padding-left: 15px;
  }

  .home2ndFBox .h2nImg1 img,
  .home2ndFBox .h2nImg2 img {
    max-width: 100%;
  }

  .h2nTextBox span {
    width: 100%;
    margin-top: 0px;
  }

  /* .disBg {
    top: auto;
    left: auto;
    position: inherit;
    width: 100%;
    height: inherit;
    max-width: 100%;
    margin-bottom: 30px;
  } */
  /* 
  .discoverySection {
    height: auto;
    padding-left: 0;
  }

  .discoverySection .dicText1 {
    font-size: 12px !important;
  }

  .discoverySection .dicText2 {
    font-size: 28px !important;
    line-height: 1.2 !important;
    margin: 1rem 0;
  }

  .discoverySection .dicText2 span {
    font-size: 32px !important;
  } */

  .shopTextBox {
    width: 100%;
    height: inherit;
    gap: 0%;
    padding: 0 15px;
    padding-top: 50px;
    flex-wrap: wrap;
  }

  .shopTBHeader {
    width: 100% !important;
    font-size: 28px !important;
    color: #fff;
    line-height: 1.2 !important;
    margin-bottom: 1rem !important;
  }

  .shopSecondTextBox {
    width: 100%;
  }

  .threeTBox {
    gap: 10px;
    padding-bottom: 100px;
    flex-wrap: wrap;
  }

  .imageFBox p {
    bottom: -5%;
    left: 0;
    width: 100%;
  }

  .meticulousSection {
    padding: 0 15px;
    gap: 50px;
    margin-bottom: 100px;
    margin-top: 100px;
    flex-wrap: wrap;
  }

  .metTextBox {
    width: 100%;
  }

  .metImgBox {
    width: 100%;
  }

  .AmbassadorsSection {
    padding: 0 15px;
  }

  .ambHeader {
    font-size: 28px !important;
    color: #fff;
    line-height: 1.2 !important;
  }

  .ambCardBox {
    flex-wrap: wrap;
  }

  .ambCard {
    width: 100%;
  }

  .foodSection {
    padding: 0 15px;
  }

  .footTitle2 {
    max-width: 100%;
    font-size: 28px !important;
    text-align: center;
    line-height: 1.2 !important;
    margin: 20px auto !important;
  }

  .footTitle2 span {
    font-size: 32px !important;
  }

  .foodVideoSection {
    margin: 30px 0;
  }

  .chefSection {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    padding: 0 15px;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .chefTextBox {
    width: 100%;
  }

  .chefImgBox {
    width: 100%;
  }

  .chefSection .ambHeader span {
    font-size: 32px !important;
  }

  .chefSection .summery14 {
    width: 100%;
    margin: 1rem 0;
  }

  .discoverBtn {
    margin: 30px 0;
  }

  .ourValueSection .ambHeader {
    font-size: 28px !important;
    padding-left: 0;
  }

  .ourValueSection .ambHeader span {
    font-size: 32px !important;
  }

  .ourValueSection .summery14 {
    width: 100%;
    margin-top: 1rem;
    padding-left: 15px;
  }

  /* .ovCard {
    width: 100%;
  } */

  .ovTbHeader {
    font-size: 20px !important;
  }

  .ourValueSection .summery14 {
    padding-left: 0 !important;
  }

  .mailSection {
    margin-bottom: 0;
    padding: 100px 15px;
  }

  .mailSection .ambHeader {
    font-size: 28px !important;
  }

  .summery14 {
    font-size: 12px !important;
    color: #cccccc;
  }

  .ourValueSection {
    padding: 0 15px;
  }

  .MailCheckBox p {
    font-size: 10px;
  }
}
/* Desktop view */
@media only screen and (min-width: 1000px) {
  .ambHeader {
    font-size: 51px !important;
  }

  .mailSection .summery14 {
    width: 30%;
  }

  .mailInputBox {
    width: 455px;
  }

  .MailCheckBox {
    width: 455px;
    margin-top: 40px;
  }

  .mailSection .ambHeader {
    font-size: 38px !important;
  }

  .imageFBox .image2 {
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }

  .imageFBox .image1 {
    webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }

  .imageFBox:hover .image2 {
    -webkit-transform: translate(10%, -10%);
    -moz-transform: translate(10%, -10%);
    -ms-transform: translate(10%, -10%);
    transform: translate(10%, -10%);
  }

  .imageFBox:hover .image1 {
    webkit-transform: translate(-5%, 5%);
    -moz-transform: translate(-5%, 5%);
    -ms-transform: translate(-5%, 5%);
    transform: translate(-5%, 5%);
  }
  /* .ovCard {
    min-width: 530px;
    min-height: 490px;
  } */
  .home2ndFBox {
    width: 83%;
    margin-bottom: 100px;
    margin-top: 0px;
  }
  .h2nTextBox p {
    font-size: 38px !important;
    line-height: 40px;
  }
  .home2ndSection {
    padding: 203px 27px 5px 33px;
  }
  .discoverySection {
    padding: 70px;
    justify-content: center;
    height: 756px;
    margin-bottom: 230px;
    margin-top: 130px;
  }
  .disBg {
    width: 93%;
    margin-left: 48px;
    top: 0px;
    left: 0px;
    position: absolute;
    height: 100%;

    /* margin-bottom: 20px; */
  }
}
/* Desktop view */
@media only screen and (min-width: 1400px) {
  /* .ovCard {
    min-width: 460px;
    min-height: 507px;
  } */
}
@media only screen and (min-width: 1000px) {
  /* .disBg {
    width: 93%;
  } */
}
