.model-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  width: 50%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}


.modal-container-image {
  position: relative;
  height: 100%;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.modal-body {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
}

button {
  width: 50px;
  border: none !important;
}


.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none !important;
  cursor: pointer;
  padding: 20px 15px;
  overflow: hidden;
  width: 50px !important;
}

.close-icon {
  font-size: 1.5rem;
  color: black;
  position: relative;
  top: 5px;
  right: 15px;
  z-index: 1;
}

.close-btn p {
  position: absolute;
}


.close-btn span {
  position: absolute;
  transition: transform 0.5s ease;
  background-color: gray;
}

.top-border, .bottom-border {
  height: 1px;
  width: 100%;
  left: 0;
  transform: scaleX(1);
}

.top-border {
  top: 0;
  /* transform: scaleX(0); */
  transform-origin: left;
}

.bottom-border {
  bottom: 0;
  /* transform: scaleX(0); */
  transform-origin: right;
}

.left-border, .right-border {
  width: 1px;
  height: 100%;
  top: 0;
  transform: scaleY(1);
}

.left-border {
  left: 0;
  /* transform: scaleY(0); */
  transform-origin: bottom;
}

.right-border {
  right: 0;
  /* transform: scaleY(0); */
  transform-origin: top;
}


.close-btn:hover .top-border,
.close-btn:hover .bottom-border {
  transform: scaleX(0);
}

.close-btn:hover .left-border,
.close-btn:hover .right-border {
  transform: scaleY(0);
}

form {
  padding: 0px !important;
}

.newsletter-form input {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}


.select-menu {
  width: 300px;              
  height: 50px;
  padding: 10px;              
  font-size: 16px;           
  color: #333;              
  background-color: #fff;   
  border: 2px solid #ccc;   
  border-radius: 8px;        
  outline: none;             
  transition: all 0.3s ease; 
  cursor: pointer;
}


.select-menu:hover {
  border-color: #666;
  background-color: #f9f9f9;
}


.select-menu:focus {
  /* border-color: #007bff; */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


.select-menu option {
  color: #000;  /* Option text color */
  background-color: #fff;
}




.submit-btn {
  margin-top: 20px !important;
  background: black;
  color: white;
  width: 300px;
  padding: 20px 20px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
}

.submit-btn:hover {
  background: #333;
}

@media (max-width: 768px) {
  .modal-content {
    grid-template-columns: 1fr;
    position: fixed;
    bottom: 0;               
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;  
    border-radius: 20px 20px 0 0; 
  }

  .modal-body {
    align-items: center;
  }

  .modal-title {
    text-align: center;
  }

  .modal-container-image {
    display: none;          
  }
}