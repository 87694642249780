.validationerr {
    color: red !important;
    font-size: 12px;
}

.terms-link {
    text-decoration: none;
    color: inherit;
    position: relative;
  }
  
  .terms-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.5px;
    background-color: white;
    bottom: -2px;
    left: 0;
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  
  .terms-link:hover::after {
    transform: scaleX(0); 
  }
  
