.individualPreferenceBox {
  display: flex;
  width: 75%;
  flex-direction: row;
  margin: 15px 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
}

.individualPreferenceBox input {
  margin: 34px 14px !important;
  width: 100% !important;
  color: #000;
}

.textContent {
  display: flex;
  gap: 17px;
  flex-direction: column;
}
