.accountContainer {
  color: #fff;
  height: calc(var(--vh, 1vh) * 100); /* Full viewport height */
}

.bannerContainer {
  width: 100%;
  height: 450px; /* Full viewport height */
  /* overflow: hidden; */
  /* Hide any overflow */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topImg {
  top: 150px;
  /* left: 0px; */
  position: absolute;
  width: 100%;
  height: 300px;
}

.profileHeaderM {
  font-size: 14px !important;
  color: #fff;
  z-index: 1;
  padding-left: 45px;
}

.profileHeaderL {
  font-size: 35px !important;
  color: #fff;
  z-index: 1;
  padding-left: 45px;
}

.profileCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  justify-content: center;
  padding: 20px;
  gap: 40px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.cardProfile {
  width: 24%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 40px 24px;
  height: 200px;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.cardProfile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.cardHeading {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 24px;
  width: 100%;
}

.cardSubtitle {
  margin-top: 24px;
  width: 100%;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.card-subtitle {
  font-size: 12px;
  color: #333;
}
