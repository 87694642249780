.typeDrop {
  right: 230px;
}

@media only screen and (min-width: 1000px) {
  .caviarListPage .winesProducts {
    width: 23% !important;
  }
}

@media only screen and (min-width: 768px) {
  .caviarListPage .winesProducts .wineProductImages {
    min-height: 25vw !important;
  }
}
