.caviarListPage {
  width: 100%;
  padding: 0 2%;
}
.caviarListBox {
  width: 100%;
  border-top: 1px solid #ffffff78;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-bottom: 100px;
}

.caviarListPage .productPageSuhHeader {
  width: 100%;
}
.caviarListPage .TTItem {
  width: 48%;
}
.caviarListPage .TTItem .imageFBox {
  width: 100%;
  min-width: unset;
  min-height: 235px;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .caviarListPage .TTItem .imageFBox {
    max-width: 350px;
    min-height: 412px;
  }
  .caviarListBox {
    margin-bottom: 0;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .caviarListPage .TTItem {
    width: 25%;
  }
  .caviarListPage .productPageSuhHeader {
    width: 50%;
  }
  .caviarListPage .dropBox {
    top: unset;
    bottom: 15px !important;
  }
}
