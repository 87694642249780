.videoContainer {
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden !important;
}

.foodVideoSection {
  width: 100%;
  /* height: 37.5vw !important; */
  overflow: hidden;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  /* aspect-ratio: 19 / 9; */
}

.playButton {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: top 0.1s ease, left 0.1s ease;
}

.videoContainer:hover .playButton {
  pointer-events: all;
}
.vbuttonBox {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  transition: opacity 0.3s, transform 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  pointer-events: none;
  mix-blend-mode: difference;
}
.vbuttonBox {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s,
    -webkit-transform 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  transition: opacity 0.3s, transform 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  pointer-events: none;
  mix-blend-mode: difference;
}
.vbuttonBox button {
  -webkit-transform: translate(-50%, -55%);
  -moz-transform: translate(-50%, -55%);
  -ms-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
  -webkit-transition: -webkit-transform 0.25s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
