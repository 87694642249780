.garethPage {
  width: 100%;
}
.garethFBox {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 100px;
}
.grTextBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 18px;
}
.grImgBox {
  width: 100%;
}
.grImgBox img {
  width: 100%;
}
.grHeaderText {
  width: 100%;
  font-size: 28px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.grNText {
  width: 100%;
  font-size: 14px !important;
  color: #fffc;
  margin-bottom: 15px !important;
}
.garethFBox .shopSLabelTextBox {
  margin-top: 20px !important;
}
.garethSBox {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 100px;
  gap: 25px;
}
.grsImgBox {
  width: 100%;
}
.grsTextBox {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 25px;
}
.grsImgBox img {
  width: 100%;
}
.grSB1 {
  width: 100%;
  display: flex;
  align-items: center;
}
.grSB2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.grSB1 img {
  width: 100%;
}
.grSB2 img {
  width: 50%;
}
.grsb1TopText {
  font-size: 11px !important;
  color: #fffc;
}
.grsb1BoldText {
  font-size: 14px !important;
  color: #fff;
  font-weight: 600 !important;
}
.grsb1NText {
  font-size: 14px !important;
  color: #fffc;
}
.garethTBox {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 20px;
}
.garethTBox img {
  width: 100%;
}
.garethTBox .discoverBtn {
  min-width: 203px;
  display: flex;
  padding: 0;
  padding-left: 10px;
}
.garethTBox .shopSLabelTextBox {
  width: 100%;
  margin: 0;
}
.garethPage .mailSection {
  padding: 50px 20px;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .grHeaderText {
    font-size: 38px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .garethFBox {
    flex-direction: row;
    padding: 0 10%;
    margin-bottom: 200px;
  }
  .grTextBox {
    width: 50%;
    padding-top: 90px;
  }
  .grImgBox {
    width: 50%;
  }
  .grHeaderText {
    width: 77%;
  }
  .grNText {
    width: 80%;
  }
  .garethFBox .shopSLabelTextBox {
    margin-top: 70px !important;
  }
  .garethSBox {
    flex-direction: row;
    margin-bottom: 200px;
  }
  .grsImgBox {
    width: 50%;
  }
  .grsTextBox {
    width: 50%;
    flex-direction: row;
  }
  .grSB1 {
    width: 50%;
  }
  .grSB2 {
    width: 50%;
  }
  .garethTBox img {
    width: unset;
  }
  .grSB2 img {
    width: 85%;
  }
  .garethTBox {
    margin-top: 150px;
    margin-bottom: 300px;
    padding: 0 10%;
  }
  .garethPage .mailSection {
    padding: 0;
  }
}
