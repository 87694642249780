.rafaPage {
  width: 100%;
}
.rafahomeTextBox {
  width: 100%;
  height: 31vh;
  background: #000;
  display: flex;
  align-items: end;
  padding: 20px;
}
.rafahomeTextBox p {
  font-size: 40px !important;
  color: #fff;
}
.rafaImgBox1 {
  width: 100%;
  height: 48vh;
  border: 0px solid;
}

.rafaImgBox1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.RafatextBox {
  width: 100%;
  padding: 100px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #0e0e0e;
}
.RafatextBox .rafaTBHText {
  width: 100%;
  font-size: 19px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.RafatextBox .rafaTBNText {
  font-size: 14px !important;
  color: #fffc;
  width: 100%;
}
.rafaPage .foodVideoSection {
  margin: 0;
}
.RafatextBox2 {
  flex-direction: column;
  align-items: center;
  padding: 100px 25px;
}
.rafaImgSlider {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  overflow: scroll;
  scroll-behavior: smooth;
  overflow-y: hidden;
}

.rafaImgSlider::-webkit-scrollbar {
  display: none;
}
.rafaSliderImgBox {
  min-width: 315px;
  height: 226px;
}
.rafaSliderImgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.caviarBolBox {
  width: 100%;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.cavImgBox {
  width: 100%;
  height: 251px;
  display: flex;
  align-items: center;
}
.cavTextBox {
  width: 100%;
}
.cavImgBox img {
  width: 100%;
  height: 95%;
  object-fit: cover;
}
.caviText1 {
  font-size: 28px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.caviText2 {
  font-size: 14px !important;
  color: #fffc;
  margin-bottom: 28px !important;
}
.cagaliBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  gap: 30px;
  margin-bottom: 50px;
}
.cagaliTextBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cagaliImgBox {
  width: 100%;
}
.cagalText1 {
  font-size: 33px !important;
  color: #fff;
  margin-bottom: 20px !important;
}
.cagalText2 {
  font-size: 14px !important;
  color: #fffc;
  margin-bottom: 20px !important;
}
.redFBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.redFBox img {
  width: 20px;
  height: 20px;
}
.cagaliImgBox img {
  width: 100%;
}
.rafaPage .mailSection {
  padding: 50px 20px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .rafahomeTextBox {
    height: 18vh;
  }
  .rafahomeTextBox p {
    font-size: 39px !important;
  }
  .rafaImgBox1 {
    height: 82vh;
  }
  .RafatextBox {
    padding: 120px 20px;
  }
  .rafaSliderImgBox {
    min-width: 612px;
    height: 422px;
  }
  .caviText1 {
    font-size: 38px !important;
  }
  .cagalText1 {
    font-size: 51px !important;
  }
  .caviarBolBox {
    padding: 120px 20px;
  }
  .rafaPage .mailSection {
    padding: 20px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .rafahomeTextBox {
    height: 53vh;
    padding: 25px;
  }
  .rafahomeTextBox p {
    font-size: 90px !important;
    line-height: 97px !important;
  }
  .rafaImgBox1 {
    height: 100vh;
  }
  .RafatextBox {
    padding: 240px 0;
  }
  .RafatextBox .rafaTBHText {
    width: 50%;
    font-size: 21px !important;
  }
  .RafatextBox .rafaTBNText {
    width: 50%;
  }
  .RafatextBox2 {
    flex-direction: row;
    gap: 50px;
    padding: 240px 25px;
  }
  .rafaSliderImgBox {
    min-width: 1129px;
    height: 650px;
  }
  .caviarBolBox {
    padding: 240px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }
  .cavImgBox {
    width: 38%;
    height: 355px;
  }
  .cavTextBox {
    width: 38%;
  }
  .caviText2 {
    margin-bottom: 60px !important;
  }
  .cagaliBox {
    flex-direction: row;
    align-items: unset;
    padding: 0px;
    margin-bottom: 100px;
  }
  .cagaliTextBox {
    width: 42%;
  }
  .cagaliImgBox {
    width: 42%;
  }
}
