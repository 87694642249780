.footer {
  width: 100%;
  background: #262626;
  padding: 8% 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  position: relative;
  margin-top: 80px;
}
.fClogo {
  width: 166px;
}
.fLastBox {
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}
.fLastBoxMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-top: 50px;
}
.fbmRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fbmRow p {
  font-size: 16 !important;
  color: #cccc;
}
.fLastInBox {
  width: 14%;
}

.fLastInBox p {
  font-size: 14px !important;
  color: #ccc;
}

.fLastInBox .TextFlipBox {
  width: 100%;
  align-items: start;
}

.fMidBox {
  width: 100%;
  margin-top: 50px;
  position: relative;
}

.fMidInBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fRowHeader {
  font-size: 12px !important;
  color: #fff;
  margin-bottom: 5px !important;
}

.fMidBox .TextFlipBox {
  width: 100%;
  align-items: start;
}

.fMidBox .TextFlipBox p {
  font-size: 12px !important;
  color: #8c8c8c;
}

.footer .navItemTExt {
  font-weight: 400 !important;
}
.fLastBoxMobile .TextFlipBox {
  width: 120px;
}
.deskTopBox {
  display: none;
}
.mobileFBox {
  width: 20%;
  display: flex;
  position: absolute;
  left: 60%;
  top: 18px;
}
.FfooterBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;
}
.Accessories .TimesNowText {
  font-style: normal;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .footer {
    padding: 8% 4%;
  }
  .mobileFBox {
    left: 50%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .footer {
    padding: 8% 10%;
  }

  .mobileFBox {
    display: none;
  }
  .deskTopBox {
    display: flex;
  }
  .fMidInBox {
    width: 20%;
    min-width: 150px;
  }
  .fLastBoxMobile {
    display: none;
  }
  .fLastBox {
    display: flex;
  }
  .fLastInBox {
    width: 20%;
  }
  .FfooterBox {
    flex-direction: row;
    justify-content: space-between;
    gap: unset;
  }
  .rightSTab {
    padding-left: 10%;
  }
  .fRowHeader {
    margin-bottom: 20px !important;
  }
  .fMidBox {
    margin-top: 100px;
  }
}
