.n25Page {
  width: 100%;
  margin-top: 77px;
  position: relative;
}
.nBox1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.nb1InnerBox {
  width: 100%;
}
.nb1BtnBTab {
  display: none;
  flex-direction: column;
  gap: 12px;
}
.nb1HeaderRText {
  font-weight: 500 !important;
}
.nBox2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
.nb2ImgBox {
  width: 100%;
}
.nBox2 img {
  width: 100%;
}
.nb2ImgBox p {
  font-size: 12px !important;
}
.nBox3 {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 50px;
  padding: 20px;
}
.nBox3 p {
  width: 100%;
}
.nb1HeaderRTextBox {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
}
.nb1PText p {
  width: 100%;
}
.n25Page .mailSection {
  padding: 20px;
}
.n25DropIcon {
  width: 25px;
  transition: all 0.5s;
  position: absolute;
  right: 0px;
}
.bottomMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(43, 43, 43, 0.555);
  align-items: end;
  z-index: 999;
}
.bmInnerBox {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: #000;
  padding: 50px;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .nBox1 {
    padding-top: 50px;
  }
  .nb1PText p {
    width: 60%;
  }
  .nBox2 {
    margin: 100px 0;
  }
  .nb2ImgBox {
    width: 60%;
  }
  .nBox3 p {
    width: 60%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .nBox1 {
    flex-direction: row;
    padding: 0;
    padding-top: 50px;
    padding-left: 30px;
  }
  .nb1InnerBox {
    width: 26%;
  }
  .nb1BtnBTab {
    display: flex;
  }
  .nb2ImgBox {
    width: 33%;
  }
  .nb1HeaderRTextBox {
    justify-content: start;
    margin-bottom: 0px;
  }
  .nBox3 {
    justify-content: end;
    align-items: center;
    padding: 0px;
    padding-right: 20%;
    margin-bottom: 50px;
  }
  .nBox3 p {
    width: 30%;
  }
  .nb1PText p {
    width: 100%;
  }
  .n25DropIcon {
    display: none;
  }
  .bottomMenu {
    display: none !important;
  }
}
