.productPage {
  width: 100%;
  padding: 0 2%;
}
.productHeaderBox {
  width: 100%;
  padding-top: 10%;
  padding-bottom: 25px;
  margin-top: 77px;
  position: relative;
}
.ambHeader {
  margin-bottom: 10px !important;
}
.dropBox {
  width: 200px;
  height: 40px;
  border-bottom: 1px solid #fff;
  position: absolute;
  top: -4px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  cursor: pointer;
}
.dropItemBox {
  width: 100%;
  transition: all 0.5s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 38px;
}
.dropItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  background: #fff;
  padding-left: 17px;
  cursor: pointer;
  transition: 0.3s;
}
.dropItem:hover {
  background: #000;
}
.dropItem:hover p {
  color: #ffffff;
}
.dropItem p {
  color: #000;
  transition: 0.3s;
}

.productListBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #ffffff78;
}
.productPageSuhHeader {
  font-size: 14px !important;
  color: #cccccc;
  width: 100%;
}
.productImgBox {
  width: 100%;
  height: 253px;
}
.productCard {
  width: 100%;
  margin-bottom: 25px;
}
.productImgBox img {
  width: 100%;
  height: 100%;
}
.pCardBText {
  font-size: 21px !important;
  margin-top: 10px !important;
  font-family: __galano_7b399e !important;
  color: #fff;
}
.productCard .shopSLabelTextBox {
  margin-top: 12px;
}
.productCard .shopSLabelTextBox span {
  color: #fffc;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .productHeaderBox {
    padding-top: 4%;
  }
  .productPageSuhHeader {
    width: 85%;
  }
  .productImgBox {
    height: 472px;
  }
  .dropBox {
    bottom: 22px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .productHeaderBox {
    padding-top: 10%;
  }
  .productPageSuhHeader {
    width: 42%;
  }
  .productListBox {
    flex-direction: row;
    align-items: unset;
    padding: 60px 0;
  }
  .productCard {
    width: 32%;
    margin-bottom: 0;
  }
  .productImgBox {
    height: 345px;
  }
}
