.blogPage {
  width: 100%;
  padding-top: 84px;
}
.TopHeaderBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thbHeader {
  font-size: 25px !important;
  color: #fff;
  font-family: __galano_7b399e !important;
  text-align: center;
  margin-bottom: 10px !important;
}
.thbSummery {
  width: 95%;
  font-size: 14px !important;
  color: #fff;
  font-family: __galano_7b399e !important;
  text-align: center;
}
.blogImgBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 18px;
  margin-top: 60px;
}
.blogImg {
  width: 100%;
}
.blogImg img {
  width: 100%;
}
.blogText {
  width: 100%;
}
.thbHeaderSummery {
  font-size: 14px;
  font-weight: 600 !important;
  color: #fff;
}
.blogText p {
  width: 100%;
  text-align: start;
}
.blogImgBox .thbSummery {
  font-size: 14px !important;
}
.atSosTextBox {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
}
.atSosText {
  font-family: __timesNow_ce0a62 !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  font-style: italic !important;
  text-align: center;
  line-height: 40px !important;
  margin-top: 60px !important;
  width: 100%;
}
.blogPage .ProductList {
  padding: 0 20px;
}

.blogPage .productHeaderBox {
  padding-top: 0;
}
.blogImgBox2 {
  flex-direction: column-reverse;
}
.blogPage .mailSection {
  padding: 0 20px;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .blogPage {
    padding-top: 140px;
  }
  .thbHeader {
    font-size: 40px !important;
    margin-bottom: 0 !important;
  }
  .atSosText {
    font-size: 40px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .thbSummery {
    width: 50%;
  }
  .blogImgBox {
    flex-direction: row;
  }
  .blogImg {
    width: 45%;
  }
  .blogText {
    width: 55%;
  }
  .blogImg img {
    width: 100%;
  }
  .atSosText {
    width: 73%;
  }
}
