.cookiePop {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 99999;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookieSettingIcon {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.cookiePopBox {
  width: 380px;
  /* height: 420px; */
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 999;
  border-radius: 12px;
  background: #fff;
  flex-direction: column;
  overflow: hidden;
}
.cookieNav {
  width: 100%;
  min-height: 55px;
  padding: 0 12px 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #8080805c;
}
.cookieNav p {
  font-size: 14px !important;
  color: #141414;
  font-weight: 600 !important;
}
.coPopMainSection {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #8080805c;
  position: relative;
}
.cookieFooter {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: absolute;
  bottom: 0px;
  border-top: 1px solid #8080805c;
}
.coBtn {
  width: 45%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}
.coBtn p {
  font-weight: 500 !important;
  font-size: 16px !important;
}
.coPInTextBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  position: relative;
}
.copText1 {
  font-size: 15px !important;
  color: #000;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
}
.coIWLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}
.coIWLabel img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.copText2 {
  font-size: 15px !important;
  color: #000;
  font-weight: 500 !important;
}
.BlueActiveTText {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #1032cf !important;
  cursor: pointer;
}
.BlueActiveTText:hover {
  color: #1033cfcb !important;
}
.DDropArrow {
  transition: all 0.5s;
}
.cookieDetailBox {
  width: 100%;
  padding: 16px;
  background: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 127px;
  margin-top: 10px;
}
.cdTLabel {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #000;
}
.cdTValue {
  font-size: 14px !important;
  color: #000;
  margin-bottom: 16px !important;
}
.Cbnr {
  position: absolute;
  bottom: 83px;
  right: 19px;
  width: 200px;
  opacity: 0.5;
  cursor: pointer;
}
.coBtn p {
  font-size: 13px !important;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .cookiePopBox {
    width: 450px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .coBtn p {
    font-size: 16px !important;
  }
}
