.emptyContainer {
  align-items: center !important;
  justify-content: center !important;
  margin-top: 100px;
}

.bVariant {
  color: #000;
}

.addressPageContainer {
  width: 100%;
  display: flex;
}

.newAddressBox {
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 15px 20px;
  gap: 27px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
}

.addNewIcon {
  width: 15px;
  height: 15px;
  display: flex;
}
.addNewBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  cursor: pointer;
}

.btnBox {
  display: flex;
}

.addressContainer {
  display: flex;
  width: 40%;
  flex-direction: column;
  margin: 15px 20px;
  gap: 27px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
}

.addInfo {
  min-height: 170px;
  padding-bottom: 25px;
  border-bottom: 1px solid #000;
}

.addInfo .addressText {
  font-size: 14px;
  color: #333;
  z-index: 1;
  padding-left: 45px;
}

.emptyIcon {
  width: 80px;
  height: 80px;
  display: flex;
}

.addLink {
  margin-top: 40px;
}

.addLink button {
  width: 170px;
  color: #000;
}

.addLink p {
  text-decoration: underline !important;
  cursor: pointer;
}
