.blog2Page .TopHeaderBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.btPHeader {
  width: 100%;
  font-size: 28px !important;
  font-weight: 650 !important;
  line-height: 45px !important;
  text-align: center;
  color: #fff;
  margin-bottom: 10px !important;
}
.btPSummery {
  width: 100%;
  font-size: 14px !important;
  color: #fff;
  text-align: center;
}
.BigImgBox {
  width: 100%;
  margin-top: 20px;
}
.BigImgBox img {
  width: 100%;
}

.blog2Page .blogImgBox {
  margin-top: 75px;
  border-bottom: 1px solid #fffc;
  padding-bottom: 15px;
}
.BigImgBox .desk {
  display: none;
}
/* Tabe view */
@media only screen and (min-width: 768px) {
  .btPHeader {
    font-size: 38px !important;
    font-weight: 650 !important;
    line-height: 72px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .btPHeader {
    width: 50%;
    font-size: 64px !important;
    font-weight: 650 !important;
    line-height: 72px !important;
  }
  .btPSummery {
    width: 50%;
  }
  .BigImgBox .mobe {
    display: none;
  }
  .BigImgBox .desk {
    display: block;
  }
}
