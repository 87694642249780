.Accessories .productCard .pCardBText {
  font-size: 14px !important;
  color: #ccc;
}
.Accessories .productListBox {
  justify-content: start;
  gap: 25px;
  flex-direction: row;
  flex-wrap: wrap;
}
.Accessories .productCard {
  width: 46%;
  position: relative;
}
.Accessories .productCard img {
  position: absolute;
  height: 80%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  object-fit: cover;
}

.Accessories .shopSLabelTextBox {
  width: 100%;
}
.Accessories .ambHeader {
  line-height: 40px !important;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .Accessories .productListBox {
    justify-content: space-between;
  }
  .Accessories .productCard {
    width: 48%;
  }
  .Accessories .productCard img {
    height: 88%;
  }
  .ourText {
    display: flex;
    gap: 15px;
  }
  .Accessories .ambHeader {
    line-height: 55px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .Accessories .productListBox {
    justify-content: start;
  }
  .Accessories .productCard {
    width: 23.5%;
  }
  .Accessories .productCard img {
    height: 83%;
  }
  .Accessories .shopSLabelTextBox {
    width: 100%;
  }
  .ourText {
    display: block;
    gap: unset;
  }
}

@media only screen and (min-width: 1100px) {
  .Accessories .productImgBox {
    height: 280px;
  }
}
@media only screen and (min-width: 1200px) {
  .Accessories .productImgBox {
    height: 300px;
  }
}
@media only screen and (min-width: 1300px) {
  .Accessories .productImgBox {
    height: 350px;
  }
}
@media only screen and (min-width: 1400px) {
  .Accessories .productImgBox {
    height: 390px;
  }
}
@media only screen and (min-width: 1500px) {
  .Accessories .productImgBox {
    height: 400px;
  }
}
@media only screen and (min-width: 1600px) {
  .Accessories .productImgBox {
    height: 405px;
  }
}
