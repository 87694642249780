:root{
  --toastify-toast-width: 500px;
}
.Toastify__close-button--light{
  width: unset !important;
  border: unset !important;
}
.accDetailContainer {
  flex-direction: column;
  margin-top: 100px;
  /* min-height: 100vh !important;  */
}

.heading {
  font-weight: bold;
}

.heading .infoHeader {
  font-size: 18px;
  font-weight: bold !important;
  color: #333;
  padding-left: 45px;
}

.pInfo .infoText {
  font-size: 14px;
  color: #333;
  z-index: 1;
  padding-left: 45px;
}

/* .bVariant {
  color: #333 !important;
  font-size: 18px !important;
  font-weight: 500;
} */

.individualPageContainer {
  display: flex;
  width: 75%;
  flex-direction: column;
  margin: 15px 20px;
  gap: 27px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
}

form {
  border-bottom: 1px solid black;
  padding-bottom: 25px;
  padding-left: 45px;
}

.pInfo {
  padding-bottom: 25px;
  border-bottom: 1px solid #000;
}

.cancelText {
  padding-left: 45px;
  color: #333 !important;
  font-size: 18px !important;
}

.editText {
  padding-left: 45px;
  color: #333 !important;
  font-size: 18px !important;
}

.uName {
  display: flex;
  gap: 30px;
  padding-bottom: 20px;
}

.uDOB {
  display: flex;
  gap: 30px;
  padding-bottom: 20px;
}

input {
  margin: 8px 0 !important;
  width: 100% !important;
  padding: 15px !important;
  box-sizing: border-box !important;
}

.uDOB input {
  margin: 8px 0 !important;
  padding: 15px !important;
  box-sizing: border-box !important;
}

button {
  width: 100px;
  border: 1px solid black !important;
}
