.workListBox {
width: 100%;
border: 1px solid #eaecf0;
border-radius: 16px;
overflow: scroll;
display: flex;
flex-direction: column;
}
.workspaceListBoxV1 .haderRow, .workspaceListBoxV1 .TabelRow {
  justify-content:start!important
}
.haderRow {
  width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    /* background: #f2f4f7; */
    justify-content: start;
    padding: 0 3%;
}
.workspaceListBoxV1 .haderRow, .workspaceListBoxV1 .TabelRow {
  justify-content:start!important;
}
@media only screen and (min-width: 1000px) {
  .TabelRow {
      padding: 0 3%;
  }
}
.TabelRow {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0 5%;
  border: 1px solid #eaecf0;
}
.TabelRow .navFsel {
  padding: 0 10px !important;
}
.Tabelsel {
  display: flex;
  align-items: center;
  position: relative;
}
.workListBox .Tabelcentersel {
  justify-content: center !important;
}
.Tabelsel {
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
}
.lv {
  margin: 22.5px auto;
}

.lu {
  height: 29px;
}
.lt {
  width: 29px;
}
.ls {
  border-radius: 50%;
}
.lr {
  background: rgba(189, 191, 191, 1);
}
.g {
  position: relative;
}
.mb {
  border-right: 1px solid rgba(255, 255, 255, 1);
}

.ma {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.lz {
  height: 10px;
}
.ly {
  top: 45%;
}
.lx {
  left: 35%;
}
.lw {
  transform: rotate(45deg) translate(-50%, -50%);
}
.hf {
  width: 6px;
}
.z {
  position: absolute;
}
.mc {
  background: rgba(208, 178, 147, 1);
}
.md {
  background: rgba(218, 206, 194, 1);
}



.howDoesWorkd{
    direction: ltr;
    background: red;
    max-width : 1440px;
    margin: 0 auto;
}
.dt {
    direction: ltr;
    background: blue;
    width: calc(100% - 64px + 24px);
    margin-left: 8px;
    align-items: stretch;
    -webkit-box-align: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
}
.gd{
    direction: ltr;
    padding : 8.333333333333334%;
    background: #10d4e2;
    max-width: calc(83.33333333333334% - 24px);
    flex-basis: calc(83.33333333333334% - 24px);
    float: left;
    flex: 1 0 auto;
    box-sizing: content-box;
}
.hn{
    justify-content: space-between;
    -webkit-box-pack: justify;
    direction: ltr;
    background: green;
    display: flex;
    margin-top: 56px;
}
.gp{
    margin-right: 16px;
    max-width: 325px;
    align-items: center;
    -webkit-box-align: center;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    text-align: center;
    display: flex;
    width: 100%;
}
/* ------------------------------------------------------------------ */
.rafaPage {
    width: 100%;
}
  .rafahomeTextBox {
    width: 100%;
    height: 31vh;
    background: #000;
    display: flex;
    align-items: end;
    padding: 20px;
  }
  .rafahomeTextBox p {
    font-size: 40px !important;
    color: #fff;
  }
  .rafaImgBox1 {
    width: 100%;
    height: 48vh;
    border: 0px solid;
  }
  
  .rafaImgBox1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .RafatextBox {
    width: 100%;
    padding: 100px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #0e0e0e;
  }
  .RafatextBox .rafaTBHText {
    width: 100%;
    font-size: 19px !important;
    color: #fff;
    margin-bottom: 20px !important;
  }
  .RafatextBox .rafaTBNText {
    font-size: 14px !important;
    color: #fffc;
    width: 100%;
  }
  .rafaPage .foodVideoSection {
    margin: 0;
  }
  .RafatextBox2 {
    flex-direction: column;
    align-items: center;
    padding: 100px 25px;
  }
  .rafaImgSlider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    overflow: scroll;
    scroll-behavior: smooth;
    overflow-y: hidden;
  }
  
  .rafaImgSlider::-webkit-scrollbar {
    display: none;
  }
  .rafaSliderImgBox {
    min-width: 315px;
    height: 226px;
  }
  .rafaSliderImgBox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .caviarBolBox {
    width: 100%;
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .cavImgBox {
    width: 100%;
    height: 251px;
    display: flex;
    align-items: center;
  }
  .cavTextBox {
    width: 100%;
  }
  .cavImgBox img {
    width: 100%;
    height: 95%;
    object-fit: cover;
  }
  .caviText1 {
    font-size: 28px !important;
    color: #fff;
    margin-bottom: 20px !important;
  }
  .caviText2 {
    font-size: 14px !important;
    color: #fffc;
    margin-bottom: 28px !important;
  }
  .cagaliBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    gap: 30px;
    margin-bottom: 50px;
  }
  .cagaliTextBox {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .cagaliImgBox {
    width: 100%;
  }
  .cagalText1 {
    font-size: 33px !important;
    color: #fff;
    margin-bottom: 20px !important;
  }
  .cagalText2 {
    font-size: 14px !important;
    color: #fffc;
    margin-bottom: 20px !important;
  }
  .redFBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .redFBox img {
    width: 20px;
    height: 20px;
  }
  .cagaliImgBox img {
    width: 100%;
  }
  .rafaPage .mailSection {
    padding: 50px 20px;
  }
  /* Tabe view */
  @media only screen and (min-width: 768px) {
    .rafahomeTextBox {
      height: 18vh;
    }
    .rafahomeTextBox p {
      font-size: 39px !important;
    }
    .rafaImgBox1 {
      height: 82vh;
    }
    .RafatextBox {
      padding: 120px 20px;
    }
    .rafaSliderImgBox {
      min-width: 612px;
      height: 422px;
    }
    .caviText1 {
      font-size: 38px !important;
    }
    .cagalText1 {
      font-size: 51px !important;
    }
    .caviarBolBox {
      padding: 120px 20px;
    }
    .rafaPage .mailSection {
      padding: 20px;
    }
  }
  
  /* Desktop view */
  @media only screen and (min-width: 1000px) {
    .rafahomeTextBox {
      height: 53vh;
      padding: 25px;
    }
    .rafahomeTextBox p {
      font-size: 90px !important;
      line-height: 97px !important;
    }
    .rafaImgBox1 {
      height: 100vh;
    }
    /* .RafatextBox {
      padding: 240px 0;
    } */
    .RafatextBox .rafaTBHText {
      width: 50%;
      font-size: 21px !important;
    }
    .RafatextBox .rafaTBNText {
      width: 50%;
    }
    .RafatextBox2 {
      flex-direction: row;
      gap: 50px;
      padding: 240px 25px;
    }
    .rafaSliderImgBox {
      min-width: 1129px;
      height: 650px;
    }
    .caviarBolBox {
      padding: 240px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 80px;
    }
    .cavImgBox {
      width: 38%;
      height: 355px;
    }
    .cavTextBox {
      width: 38%;
    }
    .caviText2 {
      margin-bottom: 60px !important;
    }
    .cagaliBox {
      flex-direction: row;
      align-items: unset;
      padding: 0px;
      margin-bottom: 100px;
    }
    .cagaliTextBox {
      width: 42%;
    }
    .cagaliImgBox {
      width: 42%;
    }
  }
  