
.storiesPage {
  width: 100%;
  margin-top: 100px;
  padding: 0 20px;
}
.stBox1 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
}
.stHeader {
  font-size: 40px !important;
  font-weight: 500 !important;
  /* margin-bottom: 20px !important; */
}
.stSubHeader {
  width: 100%;
  text-align: left;
}
.stImgBox1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  margin-top: 60px;
}
.stimgInBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.stimgInBox img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
.stimgInBoxV2 img {
  height: 40vh;
}
.stCardTitle {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 10px 0 !important;
}
.stCardSummery {
  font-size: 20px !important;
  font-style: italic;
}
.readMoreText {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px !important;
}
.stImgBox2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  margin-top: 50px !important;
}
.stimgInBoxV2 {
  width: 100%;
}
.stImgBox2Last {
  justify-content: start;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .stCardSummery {
    font-size: 13px !important;
  }
  .stImgBox1 {
    flex-direction: row;
    gap: 20px;
  }
  .stimgInBox {
    width: 50%;
  }
  .storiesPage {
    padding: 10% 25px 0px 25px;
  }
  .stSubHeader {
    width: 80%;
  }
  .stImgBox2 {
    flex-direction: row;
    gap: 20px;
  }
  .stimgInBoxV2 {
    width: 33%;
  }
  .lastBtnBox .shopSLabelTextBox {
    margin-top: 16%;
    margin-left: 9%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .stimgInBox img {
    height: 100vh;
  }
  .stimgInBoxV2 img {
    height: 80vh !important;
  }
  .stCardSummery {
    font-size: 20px !important;
  }
}
