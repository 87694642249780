.ContactPage {
  width: 100%;
  padding: 0 20px;
}
.contactInputSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.inputMargin {
margin: 0px 0px 25px 0px !important;
padding: 0px !important;
}

.contactInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contactInfoWidth {
  width: 50% !important;
}

.contactInputBox {
  width: 100%;
}
.textInfo1 {
  font-size: 28px !important;
}

.textInfoLeft {
  text-align: left !important;
  font-family: __timesNow_ce0a62 !important;
  font-style: italic;
}

.textInfo1 span {
  font-style: italic;
  font-family: __timesNow_ce0a62 !important;
}
.contactLabel {
  color: #fffc;
  margin-bottom: 25px !important;
  font-size: 11px !important;
}
.contactValue {
  color: #fffc;
  margin-bottom: 30px !important;
}
.contactFInBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.contactAddressBox {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contactAddressBox p {
  color: #fffc !important;
}
.addressinput {
  width: 100%;
  height: 60px;
  border: none;
  border-bottom: 1px #fffc solid;
  background: #ffffff00;
  margin-bottom: 27px;
}
.addressinput:focus {
  outline: none;
}

.inputLabel {
  font-size: 11px !important;
  color: #fff;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}
.inputMessageBox {
  width: 100%;
}
.messageInput {
  width: 100%;
  height: 180px;
  border: 1px solid #fffc;
  padding: 20px;
  background: #ffffff00;
  resize: none;
}
.messageInput:focus {
  outline: none;
}
.MailCheckBoxL {
  width: 100% !important;
}
.inputMessageBox .discoverBtn {
  width: 70px;
  padding: 15px 15px 15px 15px;
  background: #fff;
  margin-top: 40px;
}
.inputMessageBox .discoverBtn p {
  color: #000;
  transition: all 0.5s;
}
.inputMessageBox .discoverBtn:hover {
  background: #000;
}
.inputMessageBox .discoverBtn:hover p {
  color: #fff;
}
.ContactPage .mailSection {
  padding: 17% 0;
}

.validaterContact {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .contactInputSection {
    margin-top: 120px;
  }
  .textInfo1 {
    font-size: 38px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .ContactPage {
    padding: 0 25px;
  }
  .contactInputSection {
    flex-direction: row;
    margin-top: 200px;
  }
  .contactInfo {
    width: 30%;
  }
  .contactInputBox {
    width: 70%;
  }
  .textInfo1 {
    margin-bottom: 10px !important;
  }
}
