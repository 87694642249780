/* Style for the modal */
.address-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  color : #000;
}

.Toastify__toast-body div {
  color: #000 !important;
}

.address-container {
  position: relative;
  max-height: 551px;
  overflow-y: auto;
  background-color: white;
  padding: 20px;
  width: 600px;
  border-radius: 8px;
  outline: none;
  position: relative;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.closeIcon {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.popup-header {
  top: 0;
  z-index: 1000;
  background-color: white;
  position: sticky !important;
  padding: 10px;
  /* padding: unset; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
}

.profileHeaderM2 {
  font-size: 25px !important;
  color: #000;
  z-index: 1;
}

.addBookForm {
  border-bottom: 1px solid black !important;
  padding-bottom: 25px !important;
  padding: 35px;
}

.form-group {
  margin-bottom: 16px;
}

.submit-button {
  margin-top: 20px;
}
.MuiMenuItem-gutters {
 color: #000 !important;
}

/* Optional: Adjusting text size for form fields */
.MuiFormControl-root {
  margin-top: 0;
  margin-bottom: 16px;
}

.Toastify__toast-container {
  position: fixed !important;  /* Make sure it's fixed to the viewport */
  z-index: 9999 !important;    /* Higher z-index */
  top: 20px;                   /* Adjust positioning */
  right: 20px;
}