.orderContainer {
  justify-content: center !important;
  margin-top: 100px;
}

.individualOrderContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 15px 20px;
  background-color: #fff;
  border: 1px solid #b0b0b0;
  justify-content: space-between;
}

.oderBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.statusBtnBox {
  position: relative;
  margin: 50px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.cancelBtn {
  width: 150px;
  color: #000 !important;
}

.statusBtn {
  width: 150px;
  color: #000 !important;
}

.orderInfo {
  padding: 10px;
  font-size: 14px;
  color: #333;
  z-index: 1;
}

.orderInfo .orderText {
  padding: 10px;
  font-size: 20px;
  color: #333;
}

.labelName {
  padding-right: 15px;
  font-size: 18px;
  color: #333;
  z-index: 1;
  font-weight: bold !important;
}

.imgBox {
  position: relative;
  z-index: 1;
}

.productImage {
  width: 200px;
}

.prodctOrderTItemBottom {
  margin: 20px;
  width: 96%;
  background: #f6f6f6;
  border-radius: 30px;
  padding: 20px;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.progressBarBox {
  width: 2px;
  height: 83%;
  position: absolute;
  top: 20px;
  left: 30px;
  background: #1a1a1b33;
}

.progressBar {
  width: 100%;
  background: #000;
}

.statusUpdateBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding-left: 40px;
}

.suTitle {
  font-size: 24px;
  color: #000;
  margin-bottom: 10px;
}
.suTitle span {
  font-size: 24px;
  color: #6a6a6a;
  margin-left: 8px;
}

.suSubText1 {
  font-size: 20px;
  color: #000;
}
.suSubText2 {
  font-size: 20px;
  color: #6a6a6a;
}

.gapB {
  margin: 2px 0;
}
.dot {
  position: absolute;
  top: 0px;
  left: 0px;
}
.delivredText {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
