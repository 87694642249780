.tInfo {
  border-bottom: unset !important;
}
.blankContainer {
    display: flex;
    width: 75%;
    flex-direction: column;
    margin: 15px 20px;
    gap: 27px;
    padding: 20px;
}
