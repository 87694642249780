/* ===================================== DELIVERY OPTION ===================================== */
    .delivery-options {
        display: flex;
        gap: 20px;
        justify-content: space-evenly;
        margin-bottom: 15px;
    }
    
    .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 100px;
        border: 2px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        text-align: center;
    }
    
    .option.selected {
        border-color: #007bff;
        background-color: #e6f3ff;
    }
    
    .option .icon img {
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
    }
    
    .option p {
        margin: 0;
        font-size: 14px;
        color: #fffc;
    }

    .selected p {
        color : #333;
    }
    
    .option:hover {
        border-color: #007bff;
    }
/* ===================================== DELIVERY OPTION ===================================== */
.form-div input, .form-div select {
    color: #fffc;
    background: #ffffff00;
    border: none;
}
.form-div input:focus{
    outline: none;
}
.form-div .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color : #fffc !important;
}
.form-div .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color : #fffc !important;
}
.form-div .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root, .form-div .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    color : #fffc !important;
}
.form-div .css-oeq6yz-MuiButtonBase-root-MuiIconButton-root, .form-div .MuiIconButton-sizeMedium{
    border : unset !important;
}
.form-div .MuiInputLabel-outlined{
    color : #fffc !important;
}
.form-div .MuiOutlinedInput-notchedOutline{
    border : 1px solid #fffc !important;
}
/* .form-div .css-113d811-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color : #fffc !important;
} */
.form-div .MuiTextField-root{
    width: 100%  !important;
}
.couponCodeForm .css-113d811-MuiFormLabel-root-MuiInputLabel-root, .form-div .css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color : #fffc !important;
}
/* ==================================================== DATE PICKER ============================================== */
.MuiPickersDay-dayWithMargin, .MuiPickersPopper-paper{
    color : #fffc !important;
    border : 1px solid #fffc !important;
}
.css-aj5au3-MuiPaper-root-MuiPickersPopper-paper{
    background-color: #000 !important;
    border : 1px solid #fffc !important;
}
.MuiPickersDay-today{
    color : #fffc !important;
    border : 1px solid #fffc !important;
}
.MuiPickersDay-today.Mui-selected, .MuiPickersDay-dayWithMargin.Mui-selected{
    background-color: #fffc !important;
    color : #000 !important;
}
.MuiDateCalendar-root{
    background-color: #000 !important;
}
/* ==================================================== DATE PICKER ============================================== */

.pointerEvents{
    pointer-events : none !important;
    opacity: .65 !important;
}
.ContactPage {
    width: 100%;
    padding: 0 20px;
}
.contactInputSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}
.contactInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contactInputBox {
    width: 100%;
}
.textInfo1 {
    font-size: 28px !important;
}
.textInfo1 span {
    font-style: italic;
    font-family: __timesNow_ce0a62 !important;
}
.contactLabel {
    color: #fffc;
    margin-bottom: 25px !important;
    font-size: 11px !important;
}
.contactValue {
    color: #fffc;
    margin-bottom: 30px !important;
}
.contactFInBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.contactAddressBox {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.contactAddressBox p {
    color: #fffc;
}
.addressinput {
    width: 100%;
    height: 60px;
    border: none;
    border-bottom: 1px #fffc solid;
    background: #ffffff00;
    margin-bottom: 27px;
}
.InputElement {
    width: 100% !important;
    height: 60px !important;
    border: none !important;
    border-bottom: 1px #fffc solid !important;
    background: #ffffff00 !important;
    margin-bottom: 27px !important;
}
.addressinput:focus {
    outline: none;
}
.InputElement:focus {
    outline: none !important;
}
.inputLabel {
    font-size: 11px !important;
    color: #fff;
    font-weight: 500 !important;
    margin-bottom: 10px !important;
}
.inputMessageBox {
    width: 100%;
}
.messageInput {
    width: 100%;
    height: 180px;
    border: 1px solid #fffc;
    padding: 20px;
    background: #ffffff00;
    resize: none;
}
.messageInput:focus {
    outline: none;
}
.MailCheckBoxL {
    width: 100% !important;
}
.inputMessageBox .discoverBtn {
    width: 70px;
    padding: 15px 15px 15px 15px;
    background: #fff;
    margin-top: 40px;
}
.inputMessageBox .discoverBtn p {
    color: #000;
    transition: all 0.5s;
}
.inputMessageBox .discoverBtn:hover {
    background: #000;
}
.inputMessageBox .discoverBtn:hover p {
    color: #fff;
}
.ContactPage .mailSection {
    padding: 17% 0;
}
.cart-list-box{
    height: auto !important;
}
.total-value{
    font-size: 14px !important;
    color: #fff;
}
.total-lavel{
    font-size: 21px !important;
    color: #fff;
}
.cartTotalPBox{
    height: 86px;
}

.StripeForm .StripeElement {
    border: 1px solid #ffffff80;
    padding: 16.5px 14px;
}
.couponCodeForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}
.paymentProductTitle{
    font-size: 14px !important;
    color: #fff;
}
.paymentButton{
    border: 1px solid #ffffff80 !important;
}
  
/* Tabe view */
@media only screen and (min-width: 768px) {
    .contactInputSection {
        margin-top: 120px;
    }
    .textInfo1 {
        font-size: 38px !important;
    }
}
  
/* Desktop view */
@media only screen and (min-width: 1000px) {
    .ContactPage {
        padding: 0 25px;
    }
    .contactInputSection {
      flex-direction: row;
      margin-top: 200px;
    }
    .contactInfo {
      width: 50%;
    }
    .contactInputBox {
      width: 50%;
    }
    .textInfo1 {
      margin-bottom: 10px !important;
    }
  }
  