.accountPage {
  min-height: 100vh !important;
}

.accTopSection {
  width: 100%;
  height: 200px;
  /* overflow: hidden; */
  /* Hide any overflow */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accountMenus {
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
}

.menus {
  margin-left: 30px;
  margin-top: 100px;
  width: 30%;
}

.selectedMenuItem {
  background-color: #f1f1f1; /* Light gray background for selected */
  color: #000; /* Darker color for selected text */
  border-left: 2px solid black; /* Bold left border for selected */
}

.menuItem {
  font-size: 12px;
  font-weight: bold !important;
  color: #333;
  padding: 25px;
}

.menuItem:hover {
  transform: translateY(-5px);
  border-left: 1px solid black;
  cursor: pointer;
}

.individualPage {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  min-height: 100vh;
}

.individualPage h1 {
  color: #333;
  margin-top: 100px;
}
