.appBtn {
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.underSlideBtn {
  width: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 1px;
  cursor: pointer;
}
.underSlideBtn p {
  padding-bottom: 2px;
}
.underSlideBtn span {
  width: 100%;
  height: 1px;
  background-color: #8c8c8c;
  transition: all 0.1s;
  transform: scaleX(0);
  transform-origin: left;
}
.underSlideBtn:hover span {
  transform: unset;
}
.underSlideBtnActve span {
  transform: unset !important;
}
.nb1InnerBox .gapBox {
  margin: 25px 0;
}
