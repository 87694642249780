:root {
    --font-family-sans-serif: '__galano_7b399e', '__galano_Fallback_7b399e';
}
.term-condition-content p {
    margin-top: 15px !important;
    font-family: var(--font-family-sans-serif);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin-top: 30px !important;
    /* display: inline-block; */
}

.term-condition-content p strong {
    font-family: var(--font-family-sans-serif);
    font-weight: 400;
    font-size: 21px;
    line-height: 1.2;
    margin-top: 15px;
    /* display: inline-block; */
}
