/* .shipping-page h2{
    margin-top: 50px;
}

.shipping-page h2 strong {
    font-weight: bold !important;
}

.shipping-page h3 {
    margin-bottom: 15px;
}
.shipping-page h3 strong {
    font-weight: bold !important;
}
.shipping-page ul{
    padding-left: 30px !important;
    line-height: 2rem !important;
}

.shipping-page p{
    font-family: __galano_7b399e !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    letter-spacing: 0.00938em !important;
    margin-top: 13px !important;
} */

:root {
    --font-family-sans-serif: '__galano_7b399e', '__galano_Fallback_7b399e';
}
.shipping-page p {
    margin-top: 15px !important;
    font-family: var(--font-family-sans-serif);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    margin-top: 30px !important;
    /* display: inline-block; */
}

.shipping-page p strong {
    font-family: var(--font-family-sans-serif);
    font-weight: 400;
    font-size: 21px;
    line-height: 1.2;
    margin-top: 15px;
    /* display: inline-block; */
}
