.productDetailsSection {
  margin-top: 80px;
  position: relative;
}

.productDetailsSection .backtoprevPage {
  position: absolute;
  left: 2%;
  top: 3%;
  cursor: pointer;
  z-index: 15;
}

.productSlider {
  background-color: #1a1a1a;
}

.productSlider img {
  max-width: 100%;
}

.productDetails {
  padding: 2%;
}

.productDetails h2 {
  font-weight: 400;
  font-size: 38px;
  line-height: 1.2;
}

.productDetails p {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
}

.productDetails hr {
  border-color: #ffffff;
  margin: 1.5rem 0 1rem 0;
}

.flexListing {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
}

.flexListing ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 50%;
}

.flexListing ul li {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #cccccc;
  white-space: nowrap;
  display: block;
}

.flexListing ul li span {
  color: #8c8c8c;
  margin-right: 10px;
  min-width: 40px;
  display: inline-block;
}

.flexListing ul li span.right {
  color: #8c8c8c;
  margin-left: 10px;
  margin-right: 0;
}

.sizeQuantityPrice {
  margin-top: 1.5rem;
  background-color: #ffffff;
  padding: 5%;
}

.sizeQuantityPrice .size,
.sizeQuantityPrice .quantity {
  font-weight: 400;
  font-size: 11px;
  line-height: 1.2;
  text-transform: uppercase;
  color: #8c8c8c;
}

.sizeQuantityPrice .size strong {
  font-weight: 600 !important;
  color: #000000;
}

.sizeButtongroup {
  display: flex !important;
  /* justify-content: space-between; */
  align-items: flex-start;
  gap: 15px;
  margin: 15px 0 30px 0;
}

.sizeButtongroup button {
  width: 20%;
  min-height: 66px;
  background-color: #ffffff;
  border: 1px solid #cccccc !important;
  border-radius: 0 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  color: #8c8c8c;
  text-align: left;
  text-transform: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.sizeButtongroup button.Mui-disabled {
  border: 1px solid #ffffff !important;
}

.sizeButtongroup button.Mui-selected,
.sizeButtongroup button.Mui-selected:hover {
  background-color: #000000;
  border: 1px solid #000000 !important;
  color: #ffffff !important;
}

.not-available {
  display: block;
  background-color: #db371f;
  color: #ffffff !important;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 8px;
  padding: 5px;
}

.sizeQuantityPrice hr {
  border-color: #000000;
  margin: 1.5rem 0;
}

.sizeQuantityPrice button[type="submit"] {
  padding: 10px 20px;
  background-color: #000000;
  border: 1px solid #000000 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.inputNumber {
  margin: 10px 0;
  padding: 0;
  width: 126px;
  height: 44px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputNumber button {
  cursor: pointer;
}

.inputNumber button[type="button"] {
  width: 42px;
  height: 42px;
  background-color: #ffffff;
  border: none;
  font-size: 24px;
  color: #1a1a1a;
}

.inputNumber input[type="text"] {
  width: 42px;
  height: 42px;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
  color: #1a1a1a;
  text-align: center;
}

.Accordion {
}

.AccordionDetails {
  transition: all 0.5s;
  overflow: hidden;
}

.AccordionWrapper {
  padding: 5%;
}

.AccordionWrapper .Accordion {
  padding: 20px 0;
  border-bottom: 1px solid #ffffff;
}

.AccordionWrapper .AccordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.AccordionWrapper .AccordionTitle button {
  background-color: transparent;
  border: none;
}

.AccordionWrapper h6 {
  font-weight: 400;
  font-size: 21px;
  line-height: 1.2;
  color: #ffffff;
}

.AccordionWrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: #cccccc;
}

.AccordionWrapper h6 span,
.AccordionWrapper p span {
  display: block;
}

.AccordionWrapper p span {
  display: block;
  color: #cccccc;
}

.OscietraCaviarPage .mailSection {
  padding: 0 5%;
  margin-top: 80px;
}

@media only screen and (max-width: 760px) {
  .productDetailsSection {
    margin-top: 60px;
  }

  .productDetailsSection .backtoprevPage {
    left: 20px;
    top: 20px;
  }

  .productDetails {
    padding: 20px;
  }

  .productDetails h2 {
    font-size: 24px;
  }

  .flexListing {
    flex-wrap: wrap;
    gap: 0px;
  }

  .flexListing ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
  }

  .sizeQuantityPrice {
    padding: 20px;
  }

  .sizeButtongroup {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .sizeButtongroup button {
    width: 30%;
  }

  .sizeButtongroup button.Mui-disabled {
    border: 1px solid #ffffff !important;
  }

  .sizeButtongroup button.Mui-selected,
  .sizeButtongroup button.Mui-selected:hover {
    background-color: #000000;
    border: 1px solid #000000 !important;
    color: #ffffff !important;
  }

  .AccordionWrapper h6 {
    font-size: 17px;
  }
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .AccordionWrapper {
    padding: 40px;
  }
}

.carousel .thumbs-wrapper,
.carousel .carousel-status,
.carousel.carousel-slider .control-arrow {
  display: none;
}

.carousel .control-dots {
  width: 20px !important;
  margin: 0 !important;
  bottom: 50% !important;
  left: 3% !important;
}

.carousel .control-dots .dot {
  background-color: transparent !important;
  border: 1px solid #ffffff;
  opacity: 0.8;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background-color: #ffffff !important;
}
