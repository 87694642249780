@media only screen and (min-width: 1000px) {
  .theCaviarSectionFirst {
    margin-bottom: 0px !important;
    padding: 0 29px !important;
  }
}

.theCaviarSectionFirst {
  width: 100%;
  height: 180px;
}

.theCaviarSectionMain {
  width: 100%;
  height: 200px;
}

@media only screen and (min-width: 1000px) {
  .theCaviarSectionMain {
    margin-bottom: 0px !important;
    padding: 0 29px !important;
  }
}
