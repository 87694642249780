.blogPageHomeImgSection {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blogpageBg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  z-index: -1;
}
.blogPageHomeImgSection p {
  font-size: 35px !important;
  font-weight: 700 !important;
  color: #fff;
}
.blackTextBox {
  width: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fbb {
  padding: 80px 0;
}
.fbb p {
  text-align: center;
  width: 95%;
}
.bGapBox {
  margin: 10px 0;
}
.rowImgBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.rowImgBox img {
  width: 33%;
}
.fbb2 {
  padding: 40px 0;
  padding-bottom: 80px;
}
.fbb2 p {
  font-family: __timesNow_ce0a62 !important;
  font-size: 30px !important;
  line-height: 35px !important;
  color: #fff;
  text-align: center;
  width: 95%;
}

.blogpage3 .foodVideoSection {
  margin-top: 0;
  margin-bottom: 0;
}
.b3dnImg {
  width: 100%;
}
.b3dnImg img {
  width: 100%;
}
.fbb3 {
  padding: 50px 0;
}
.fbb3 .f3Header {
  font-size: 43px !important;
  font-weight: 600 !important;
  color: #fff;
}
.fbb3 .fbbNText {
  font-size: 13px !important;
  color: #fff;
  text-align: center;
  width: 95%;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .blogPageHomeImgSection {
    height: 86vh;
  }
  .fbb p {
    width: 90%;
  }

  .blogPageHomeImgSection p {
    font-size: 60px !important;
  }
  .fbb3 .f3Header {
    font-size: 64px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .blogPageHomeImgSection {
    height: 80vh;
  }
  .fbb p {
    width: 50%;
  }
  .fbb2 p {
    font-size: 58px !important;
    line-height: 56px !important;
    width: 90%;
  }
  .blogpageBg {
    object-fit: unset;
  }
}
