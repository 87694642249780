.OfferPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.offerInfo {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 80px;
}

.textInfo1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
}

.offerDescription {
    font-size: 1rem;
    color: #fff;
    max-width: 600px;
}

.offerDetails {
    text-align: left;
    margin-top: 30px;
    /* background-color: #fff; */
    border: 1px solid #fff;
    padding: 26px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.detailsHeader {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
}

.detailsInfo {
    font-size: 1rem;
    color: #fff;
}
