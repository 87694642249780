.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.slider img {
  width: 100%;
  border-radius: 10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: rgb(255, 68, 68);
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}
.parent {
  width: 100%;
  margin: 0 auto;
}

/* slider card style */

.sliderMainSection {
  width: 100%;
}
.sliderMainSection .ambHeader {
  margin-left: 25px;
}
.sliderMainSection .summery14 {
  margin-left: 25px;
  width: 26%;
}
.sliderBox {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.NewProductSlidCard {
  height: 100%;
  position: relative;
  padding: 0 !important;
}

.HSliderImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.npSCTextBox {
  position: absolute;
  bottom: 30px;
  background: #000;
  border-radius: 10px;
}
.npSCTextBox h3 {
  font-style: italic;
  font-family: __galano_7b399e !important;
  margin-bottom: 15px;
  /* padding: 10px; */
}
.npSCTextBox p {
  font-size: 14px !important;
  color: #cccccc;
  /* padding: 10px; */
}

.sliderBox button {
  width: 26px !important;
}

/*Mobile View */

@media only screen and (max-width: 767px) {
  .npSCTextBox {
    /* left: 7%; */
    padding: 10px;
  }
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .npSCTextBox {
    left: 7%;
    padding: 10px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .npSCTextBox {
    left: 7%;
    padding: 20px;
    width: 225px;
  }
}
